export const buttonProps = {
  style: {
    borderRadius: "4px",
    marginTop: "4px",
  },
};

export const buttonRadius = {
  style: {
    borderRadius: "4px",
  },
};
 

export const buttonRed = {
  style: {
    borderRadius: "4px",
    backgroundColor: 'red',
    color: '#fff'
  },
};
export const buttonLeft = {
  style: {
    marginLeft: "91%",
  },
};
