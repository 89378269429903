export const marginTop = {
  style: {
    marginTop: "22px",
  },
};

export const largeMarginTop = {
  style: {
    marginTop: "30px",
  },
};

export const redCard = {
  style: {
    width: 300,
    marginTop: 16,
    backgroundColor: "#F2DEDE",
  },
};

export const redText = {
  style: {
    color: "#AA4644",
    textTransform: "uppercase",
  } as React.CSSProperties,
};

export const yellowCard = {
  style: {
    width: 300,
    marginTop: 16,
    backgroundColor: "#FCF8E3",
  },
};

export const yellowText = {
  style: {
    color: "#8A6D3B",
    textTransform: "uppercase",
  } as React.CSSProperties,
};

export const greenCard = {
  style: {
    width: 300,
    marginTop: 16,
    backgroundColor: "#DFF0D8",
  },
};

export const greenText = {
  style: {
    color: "#3C763D",
    textTransform: "uppercase",
  } as React.CSSProperties,
};

export const primaryText = {
  style: {
    color: "#4762EA",
  },
};
